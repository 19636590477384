.header {
  font-size: 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  line-height: 28px;
}

.text {
  font-size: 14px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 300;
  line-height: 20px;
}

//TODO: This can go when fluent-ui check box required works
.required {
  color: #a4262c;
  padding-right: 12;
  font-weight: 600;
  margin: 0;
}
