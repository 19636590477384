body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// Override user agent styles
h1,
h2,
h3 {
  margin-block-start: 0;
  margin-block-end: 0;
}

// The following style was included within the fluent v0 but not in v9.
// Exclude cookie banner's children, it is styled by the WCP consent library and it does not work well with box sizing being overridden.
*:not(#cookie-banner *) {
  box-sizing: border-box;
}

#cookie-banner {
  position: fixed; /* Ensures the banner is always at the bottom */
  bottom: 0; /* Moves the banner to the bottom of the screen */
  left: 0; /* Aligns the banner to the left */
  right: 0; /* Makes the banner stretch across the screen */
  z-index: 9999; /* Ensures the banner stays on top of other elements */
}
